import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Icon,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useEffect } from "react";

// import { NavLink } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

// Assets
import { MdInfo, MdPerson, MdDateRange, MdEvent } from "react-icons/md";
import { usePostRequestWithFile, usePostRequest } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 5;

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [selectedId, setSelectedId] = useState(null);
  const { createPost, isLoading, errorResp, success } = usePostRequest(
    "audiobooks/add-chapter"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      author: "Onorio Cutane",
    },
  });

  const onSubmitChapter = (data) => {
    if (!data.streamUri || !data.chapter || !data.audioBookId) {
      Swal.fire({
        title: "Error!",
        text: "Please provide all required information",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    } else {
      createPost({ ...data });
      // console.log(data);
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "New Chapter Added!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  // DELETE A BOOK
  const deleteBook = () => {
    AllService.deleteAudioBook(selectedId)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Audio Book Deleted!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };

  //
  return (
    <>
      {/* ADD A CHAPTER */}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="24px" mb="70px">
            Add a new Chapter to an audio book
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form
            onSubmit={handleSubmit(onSubmitChapter)}
            method="POST"
            encType="multipart/form-data"
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Select Audio Book<Text color={"red"}>*</Text>
              </FormLabel>
              <Select
                placeholder="Select option"
                variant="auth"
                fontSize="sm"
                mb="12px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setSelectedId(e.target.value)}
                {...register("audioBookId", { required: true })}
              >
                {tableData.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.title}
                  </option>
                ))}
              </Select>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Chapter<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.chapter && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please input Chapter
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="eg. Chapter 5"
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("chapter", { required: true })}
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Stream URL (Audio File link)<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.streamUri && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please enter the Audio Link
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter the streamUri..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("streamUri", { required: true })}
              />

              <Button
                fontSize="sm"
                colorScheme="green"
                fontWeight="500"
                w="30%"
                mb="24px"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Adding Chapter to Audio Book..." : "Add Chapter"}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Card>
      {/* ALERT Dialog box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Audio Book
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteBook} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
