import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdLocationOn,
  MdPerson,
  MdLock,
  MdMovie,
  MdPeopleAlt,
  MdHome,
  MdMusicNote,
} from "react-icons/md";

import { HiOutlineNewspaper } from "react-icons/hi";
import { FaBook, FaMusic, FaPodcast } from "react-icons/fa";

// Admin Imports
import Users from "views/admin/users";
import Songs from "views/admin/songs";
import Books from "views/admin/books";
import Videos from "views/admin/videos";
import AudioBooks from "views/admin/audio-books";
import AudioBooksChapters from "views/admin/chapters";
import Albums from "views/admin/album";
import MainDashboard from "views/admin/default";
import Podcast from "views/admin/podcast";
import Episode from "views/admin/episodes";

// Auth Imports
import SignInCentered from "views/auth/signIn";
let userRoute = {};

const user = sessionStorage.getItem("user");
if (!user) {
  userRoute = {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  };
}
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "e-Books",
    layout: "/admin",
    icon: <Icon as={FaBook} width="20px" height="20px" color="inherit" />,
    path: "/books",
    component: Books,
  },
  {
    name: "Audio Books",
    layout: "/admin",
    icon: <Icon as={MdMusicNote} width="20px" height="20px" color="inherit" />,
    path: "/audio-books",
    component: AudioBooks,
  },
  {
    name: "Audio Books Chapters",
    layout: "/admin",
    icon: <Icon as={MdMusicNote} width="20px" height="20px" color="inherit" />,
    path: "/chapters",
    component: AudioBooksChapters,
  },
  {
    name: "Albums",
    layout: "/admin",
    icon: (
      <Icon
        as={HiOutlineNewspaper}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/albums",
    component: Albums,
  },

  {
    name: "Songs",
    layout: "/admin",
    icon: <Icon as={FaMusic} width="20px" height="20px" color="inherit" />,
    path: "/songs",
    component: Songs,
  },
  {
    name: "Podcast",
    layout: "/admin",
    icon: <Icon as={FaPodcast} width="20px" height="20px" color="inherit" />,
    path: "/podcast",
    component: Podcast,
  },
  {
    name: "Podcast Episode",
    layout: "/admin",
    icon: <Icon as={FaPodcast} width="20px" height="20px" color="inherit" />,
    path: "/episode",
    component: Episode,
  },
  {
    name: "Videos",
    layout: "/admin",
    icon: <Icon as={MdMovie} width="20px" height="20px" color="inherit" />,
    path: "/video",
    component: Videos,
  },

  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPeopleAlt} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
  },

  userRoute,
];

export default routes;
