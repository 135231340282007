import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Icon,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useEffect } from "react";
import moment from "moment";
// import { NavLink } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

// Assets
import { MdInfo, MdDateRange, MdEvent } from "react-icons/md";
import { usePostRequestWithFile } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 5;

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [selectedId, setSelectedId] = useState(null);
  const { createPost, isLoading, errorResp, success } =
    usePostRequestWithFile("podcasts/add");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      host: "Onorio Cutane",
    },
  });
  const onSubmit = (data) => {
    let file = data.cover[0];
    if (file.size > 20242880) {
      Swal.fire({
        title: "Error!",
        text: "File is larger than 20MB",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    } else {
      createPost({ ...data, cover: file });
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "Podcast Added!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      console.log(errorResp);
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  // DELETE AN Podcast
  const deletePodcast = () => {
    AllService.deletePodcast(selectedId)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Podcast Deleted!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };

  //
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdEvent} color={brandColor} />}
            />
          }
          name="Total Podcast"
          value={tableData.length}
          url="#"
        />
        {tableData.length > 0 && (
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
                }
              />
            }
            name="Latest Podcast creation Date"
            value={moment(tableData[0]?.createdAt).format("LLLL")}
            url="#"
          />
        )}
      </SimpleGrid>
      {/* here */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            All Podcast Details
          </Text>
        </Flex>
        <div style={{ overflowX: "auto" }}>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "Cover") {
                        data = (
                          <Image
                            src={cell.value}
                            alt={`image ${index}`}
                            boxSize="100px"
                            objectFit="cover"
                          />
                        );
                      } else if (cell.column.Header === "Title") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "host") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Description") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "Release Date") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {moment(cell.value).fromNow()}
                          </Text>
                        );
                      } else if (cell.column.Header === "priceIdMobile") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "episodes") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value || "0"}
                          </Text>
                        );
                      } else if (cell.column.Header === "streamUri") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "More") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Button
                              onClick={() => {
                                setSelectedId(cell.value);
                                onOpen();
                              }}
                            >
                              <Icon
                                w="24px"
                                h="24px"
                                me="5px"
                                color={"yellow.400"}
                                as={MdInfo}
                              />
                            </Button>
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>

        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>

      {/* add a Podcast*/}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="70px">
            Create Podcast
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            encType="multipart/form-data"
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Title<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please input Title
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter the title ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("title", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Description<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please input description
                </small>
              )}
              <Textarea
                style={{ border: "solid 1px grey" }}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Enter the description"
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("description", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Host<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please enter the author
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter the host ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("host", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Release Date<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please Select Date
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="date"
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("release_date", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Price<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small
                  className="text-primary-red text-xs"
                  style={{ color: "red" }}
                >
                  Please input Price
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                step="0.01"
                placeholder="Enter the price in number ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("price", { required: true })}
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                <Text size={"xl"}>Select the Cover Photo </Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                width="100%"
                ms={{ base: "0px", md: "0px" }}
                type="file"
                mb="12px"
                accept="images/*"
                {...register("cover", { required: true })}
              />

              {errors.title && (
                <small className="text-primary-red text-xs">
                  Please Select a file to upload
                </small>
              )}

              <Button
                fontSize="sm"
                colorScheme="green"
                fontWeight="500"
                w="30%"
                mb="24px"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Adding Podcast..." : "Create Podcast"}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Card>
      {/* ALERT Dialog box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Podcast
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deletePodcast} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
