import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAddTask, MdAttachMoney, MdBarChart } from "react-icons/md";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../podcast/components/Spinner";
import AllService from "../../../services/index";
import { BsBookFill, BsPeopleFill } from "react-icons/bs";

import countries from "../../../data/countries";
import { FaMusic, FaPodcast, FaStackOverflow } from "react-icons/fa";
export default function UserReports() {
  // Chakra Color Mode

  const stripe = require("stripe")(process.env.STRIPE_LIVE_SK);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [tableData, setTableData] = useState({});
  const [user, setUser] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    AllService.getDashBoardData()
      .then((res) => {
        if (res.data) {
          setTableData(res.data);
          setUser(res.data.users);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });

    // getAllPayments();
    // handleSelection();
  }, []);

  // const getAllPayments = async () => {
  //   if (total === 0) {
  //     let allPaymentss = [];
  //     let hasMore = true;
  //     let startingAfter;
  //     var pay = [];
  //     // var totals = 0

  //     while (hasMore) {
  //       const payments = await stripe.paymentIntents.list({
  //         limit: 100,
  //         starting_after: startingAfter,
  //       });
  //       allPaymentss.push(...payments.data);
  //       if (payments.has_more) {
  //         startingAfter = payments.data[payments.data.length - 1].id;
  //       } else {
  //         hasMore = false;
  //       }
  //     }
  //     try {
  //       allPaymentss.map(async (payment) => {
  //         await stripe.charges
  //           .list({ payment_intent: payment.id })
  //           .then((res) => {
  //             const customer = res?.data[0]?.billing_details?.name;
  //             let origin = res?.data[0]?.billing_details?.address.country;
  //             //---------------------
  //             const countryObj = countries.find(
  //               (nation) => nation.symbol === origin
  //             );
  //             origin = countryObj ? countryObj.country : origin;
  //             //----------------------
  //             const email = res?.data[0]?.billing_details?.email;
  //             const status = res?.data[0]?.status;
  //             pay.push({
  //               id: payment.id ? payment.id : "id not found",
  //               description: payment.description,
  //               amount: payment.amount / 100,
  //               currency: payment.currency,
  //               created: payment.created,
  //               customer: customer,
  //               origin: origin,
  //               email: email,
  //               status: status,
  //             });
  //           });
  //       });
  //     } catch (error) {
  //       console.error(`Error processing payment : ${error.message}`);
  //       return null;
  //     }

  //     setDetailedPayments(pay);

  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 5000);
  //   } else {
  //     setIsLoading(false);
  //     return;
  //   }
  // };
  // const handleSelection = async (e) => {
  //   try {
  //     let option = e === undefined ? "31536000000" : e.target.value;
  //     const startTime = Number(Date.now()) - Number(option);
  //     let periodicData = [];
  //     var totals = 0;
  //     setTotal(0);
  //     await Promise.all(
  //       detailedPayments
  //         .sort((a, b) => Number(a.created) - Number(b.created))
  //         .map((payment) => {
  //           if (Number(payment?.created) * 1000 >= startTime) {
  //             const createdDate = new Date(
  //               payment.created * 1000
  //             ).toLocaleDateString("en-GB");
  //             periodicData.push({ ...payment, created: createdDate });
  //             if (payment.status === "succeeded") {
  //               totals = totals + payment.amount;
  //             }
  //           }
  //         })
  //     );

  //     setPeriod(periodicData);
  //     setTotal((prev) => (prev = Number(totals.toFixed(2))));

  //     //=======================
  //     const sortedProducts = [
  //       { type: "books", product: [...books] },
  //       { type: "music", product: [...music] },
  //       { type: "podcasts", product: [...episodes] },
  //     ];

  //     var booksObj = { count: 0, sum: 0 };
  //     var musicObj = { count: 0, sum: 0 };
  //     var podcastsObj = { count: 0, sum: 0 };

  //     periodicData.forEach((data) => {
  //       data.description.split("1x").forEach((description) => {
  //         sortedProducts.forEach((product) => {
  //           product.product.forEach((item) => {
  //             if (
  //               description.indexOf(item.title) !== -1 &&
  //               data.status === "succeeded"
  //             ) {
  //               switch (product.type) {
  //                 case "books":
  //                   booksObj.count++;
  //                   booksObj.sum += Number(item.price);
  //                   // booksArray.push(data);

  //                   break;
  //                 case "music":
  //                   musicObj.count++;
  //                   musicObj.sum += Number(item.price);
  //                   // musicArray.push(data);
  //                   // console.log("music price===", item);
  //                   break;
  //                 case "podcasts":
  //                   podcastsObj.count++;
  //                   podcastsObj.sum += Number(item.price);
  //                   break;
  //                 default:
  //                   break;
  //               }
  //             }
  //           });
  //         });
  //       });
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const Drop = (
  //   <div>
  //     <select
  //       id="select-option"
  //       defaultValue={"86400000"}
  //       onChange={handleSelection}
  //       className="w-24 border-1 border-color px-2 py-1 rounded-md"
  //     >
  //       <option value="86400000">Day</option>
  //       <option value="604800000">Week</option>
  //       <option value="2592000000">Month</option>
  //       <option value="31536000000">Year</option>
  //       {/* <option value="option3">Custom period</option> */}
  //     </select>
  //   </div>
  // );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          {/* <div className="items-center  mt-3">{Drop}</div> */}
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
            gap="20px"
            mb="20px"
            mt="40px"
          >
            {/* <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Earning in USD"
              value={total}
              url="#"
            /> */}
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={BsPeopleFill}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Users"
              value={user}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={BsBookFill}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total e-Books"
              value={JSON.stringify(tableData.books)}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={BsBookFill}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Audio Books"
              value={JSON.stringify(tableData.audiobook)}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={FaPodcast} color={brandColor} />
                  }
                />
              }
              name="Total Podcast"
              value={JSON.stringify(tableData.podcasts)}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={FaMusic} color={brandColor} />
                  }
                />
              }
              name="Total Music"
              value={JSON.stringify(tableData.musics)}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={FaStackOverflow}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Total Albums"
              value={JSON.stringify(tableData.albums)}
              url="#"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
                />
              }
              name="Total Episode"
              value={JSON.stringify(tableData.episodes)}
              url="#"
            />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
